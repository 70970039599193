import Vue from 'vue'
import Router from 'vue-router'
import * as scrollFunctions from '~/mixins/scroll.functions.js'
import UserPage from '~/pages/user_page/_.vue'
import AuthorList from '~/pages/author_list/_.vue'
import ArticleList from '~/pages/article_list/_.vue'
import ArticlePag from '~/pages/article_page/_.vue'
import GalleryPag from '~/pages/gallery_page/_.vue'
import Recommender from '~/pages/recommender/_.vue'
import ReviewList from '~/pages/review_list/_.vue'
import BrandList from '~/pages/brand_list/_.vue'
import StorePage from '~/pages/store_page/_.vue'
import InquiryPage from '~/pages/inquiry_page/_.vue'
import InquiryList from '~/pages/inquiry_list/_.vue'
import TalkPage from '~/pages/talk_page/_.vue'
import ProductList from '~/pages/product_list/_.vue'
import TagPage from '~/pages/tag_page/_.vue'
import ComparatorPag from '~/pages/comparator_page/_.vue'
import ComparisonPag from '~/pages/comparison_page/_.vue'
import OpinionList from '~/pages/opinion_list/_.vue'
import PriceList from '~/pages/price_list/_.vue'
import ProductVariant from '~/pages/product_variant/_.vue'
import ProductPrices from '~/pages/product_prices/_.vue'
import ProductPag from '~/pages/product_page/_.vue'
import DiscountCodesList from '~/pages/discount_codes_list/_.vue'
import Calculator from '~/pages/calculator/_.vue'
import CalculatorMarathon from '~/pages/calculator_marathon/_.vue'
import CalculatorHalfMarathon from '~/pages/calculator_half_marathon/_.vue'
import Calculator10Km from '~/pages/calculator_10_km/_.vue'
import NotFoundPage from '~/pages/not_found_page/_.vue'
import RedirectingPag from '~/pages/redirecting/_.vue'
import AudiosList from '~/pages/audios_list/_.vue'
import AudiosPage from '~/pages/audios_page/_.vue'
import AuthLogin from '~/pages/auth_login/_.vue'
import AuthRedirect from '~/pages/auth_redirect/_.vue'
import AuthCallback from '~/pages/auth_callback/_.vue'
import AuthData from '~/pages/auth_data/_.vue'
import AuthNewsletter from '~/pages/auth_newsletter/_.vue'
import AuthRemember from '~/pages/auth_remember/_.vue'
import AuthPassword from '~/pages/auth_password/_.vue'
import AuthSignin from '~/pages/auth_signin/_.vue'
import CountriesRedirect from '~/pages/countries_redirect/_.vue'
import LandingPag from '~/pages/landing_page/_.vue'

Vue.use(Router)

export function createRouter () {
  return new Router({
    mode: 'history',
    base: '/',
    scrollBehavior: (to, from) => {
      scrollFunctions.init()

      if (to.hash) {
        scrollFunctions.set(to.hash)
        return false
      }

      return { x: 0, y: 0 }
    },
    routes: [
      {
        path:'/utilisateur/',
        component:UserPage
      },
      {
        path:'/articles/auteurs/:pag(\\d+)?',
        component:AuthorList,
        props:{"filter":"articles"}
      },
      {
        path:'/articles/auteurs/:autor/:pag(\\d+)?',
        component:ArticleList,
        props:{"breadcrumb":[{"text":"auteurs","href":"/articles/auteurs/"}]}
      },
      {
        path:'/articles/categories/:categoria/:pag(\\d+)?',
        component:ArticleList,
        props:{"breadcrumb":[{"text":"Articulos","href":"/articles/"}]}
      },
      {
        path:'/articles/themes/:etiqueta/:pag(\\d+)?',
        component:ArticleList,
        props:{"article":"etiqueta","filter":"tag","breadcrumb":[{"text":"etiqueta","href":"/articles/etiquetas/"}]}
      },
      {
        path:'/articles/:pag(\\d+)?',
        component:ArticleList,
        props:{"breadcrumb":[{"text":"Articulos","href":"/articles/"}]}
      },
      {
        path:'/articles/*index.amp.html',
        component:ArticlePag,
        meta:{"amp":true}
      },
      {
        path:'/articles/*',
        component:ArticlePag
      },
      {
        path:'/galeries/*.amp.html',
        component:GalleryPag,
        meta:{"amp":true}
      },
      {
        path:'/galeries/*.html',
        component:GalleryPag
      },
      {
        path:'/shoe-finder/index.amp.html',
        component:Recommender,
        meta:{"amp":true}
      },
      {
        path:'/shoe-finder/',
        component:Recommender
      },
      {
        path:'/tests/:pag(\\d+)?',
        component:ReviewList,
        props:{"breadcrumb":[{"text":"Tests","href":"/tests/"}]}
      },
      {
        path:'/tests/testeurs/:autor/:pag(\\d+)?',
        component:ReviewList,
        props:{"breadcrumb":[{"text":"Tests","href":"/tests/"}]}
      },
      {
        path:'/auteurs/:pag(\\d+)?',
        component:AuthorList
      },
      {
        path:'/marques/',
        component:BrandList
      },
      {
        path:'/magasin/:tienda/*',
        component:StorePage
      },
      {
        path:'/*/questions/*-:id.html',
        component:InquiryPage,
        props:{"prodTypeId":"1","breadcrumb":[{"text":"/Questions","href":"/chaussures-running/questions/toutes/"}]}
      },
      {
        path:'/*/questions/*/',
        component:InquiryList,
        props:{"breadcrumb":[{"text":"/Questions","href":"/chaussures-running/questions/toutes/"}],"sections":"136,143,139,140,141,142"}
      },
      {
        path:'/commentaires/articulos/*.html',
        component:TalkPage,
        props:{"breadcrumb":[{"text":"Comentarios","href":"/commentaires/articulos/"}]}
      },
      {
        path:'/chercheur/modeles/toutes/*/:q?',
        component:ProductList,
        props:{"prodTypeId":"0","breadcrumb":[{"text":"modeles","href":"/chercheur/modeles/toutes/"}]}
      },
      {
        path:'/chercheur/modeles/:marca/*/:q?',
        component:ProductList,
        props:{"prodTypeId":"0","breadcrumb":[{"text":"modeles","href":"/chercheur/modeles/toutes/"}]}
      },
      {
        path:'/chaussures/*/tag/:pag(\\d+)?',
        component:TagPage,
        props:{"typeId":"1,50,62,64,84"}
      },
      {
        path:'/chaussures/comparateur/',
        component:ComparatorPag,
        props:{"prodTypeId":"1,50,62,64,84"}
      },
      {
        path:'/chaussures/comparaison/:prd1/:prd2/*.html',
        component:ComparisonPag,
        props:{"prodTypeId":"1,50,62,64,84"}
      },
      {
        path:'/chaussures/questions/recommandations/*',
        component:InquiryList,
        props:{"prodTypeId":"1,50,62,64,84","breadcrumb":[{"text":"Chaussures","href":"/chaussures/modeles/toutes/"}],"Type":1}
      },
      {
        path:'/commentaires/chaussures/*.html',
        component:TalkPage,
        props:{"breadcrumb":[{"text":"Comentarios","href":"/commentaires/chaussures/"}]}
      },
      {
        path:'/chaussures/tests/testeurs/:pag(\\d+)?',
        component:AuthorList,
        props:{"filter":"reviews","prodTypeId":"1,50,62,64,84"}
      },
      {
        path:'/chaussures/tests/testeurs/:autor/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"1,50,62,64,84","breadcrumb":[{"text":"Reviews","href":"/tests/"}]}
      },
      {
        path:'/chaussures/tests/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"1,50,62,64,84","breadcrumb":[{"text":"Reviews","href":"/tests/"}]}
      },
      {
        path:'/chaussures/avis/toutes/:pag(\\d+)?',
        component:OpinionList,
        props:{"prodTypeId":"1,50,62,64,84","breadcrumb":[{"text":"Opiniones","href":"/avis/toutes/"}]}
      },
      {
        path:'/chaussures/modeles/toutes/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"1,50,62,64,84","breadcrumb":[{"text":"Chaussures","href":"/chaussures/modeles/toutes/"}]}
      },
      {
        path:'/chaussures/modeles/:marca/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"1,50,62,64,84","breadcrumb":[{"text":"Chaussures","href":"/chaussures/modeles/toutes/"}]}
      },
      {
        path:'/chaussures/outlet/toutes/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"1,50,62,64,84","breadcrumb":[{"text":"Chaussures","href":"/chaussures/modeles/toutes/"}]}
      },
      {
        path:'/chaussures/outlet/:tienda/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"1,50,62,64,84","breadcrumb":[{"text":"Chaussures","href":"/chaussures/modeles/toutes/"}]}
      },
      {
        path:'/chaussures/*/modele/:variant/*',
        component:ProductVariant,
        props:{"name":"ProductPrices","prodTypeId":"1,50,62,64,84"}
      },
      {
        path:'/chaussures/*/amp/prix/toutes/*',
        component:ProductPrices,
        props:{"prodTypeId":"1,50,62,64,84","breadcrumb":[{"text":"Chaussures","href":"/chaussures/modeles/toutes/"}]},
        meta:{"amp":true}
      },
      {
        path:'/chaussures/*/prix/toutes/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"1,50,62,64,84","breadcrumb":[{"text":"Chaussures","href":"/chaussures/modeles/toutes/"}]}
      },
      {
        path:'/chaussures/*/amp/prix/:tienda/*',
        component:ProductPrices,
        props:{"prodTypeId":"1,50,62,64,84","breadcrumb":[{"text":"Chaussures","href":"/chaussures/modeles/toutes/"}]},
        meta:{"amp":true}
      },
      {
        path:'/chaussures/*/prix/:tienda/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"1,50,62,64,84","breadcrumb":[{"text":"Chaussures","href":"/chaussures/modeles/toutes/"}]}
      },
      {
        path:'/chaussures/*index.amp.html',
        component:ProductPag,
        props:{"prodTypeId":"1,50,62,64,84"},
        meta:{"amp":true}
      },
      {
        path:'/chaussures/*',
        component:ProductPag,
        props:{"prodTypeId":"1,50,62,64,84"}
      },
      {
        path:'/chaussures-running/*/tag/:pag(\\d+)?',
        component:TagPage,
        props:{"typeId":"1"}
      },
      {
        path:'/chaussures-running/comparateur/',
        component:ComparatorPag,
        props:{"prodTypeId":"1"}
      },
      {
        path:'/chaussures-running/comparaison/:prd1/:prd2/*.html',
        component:ComparisonPag,
        props:{"prodTypeId":"1"}
      },
      {
        path:'/chaussures-running/questions/recommandations/*',
        component:InquiryList,
        props:{"prodTypeId":"1","breadcrumb":[{"text":"Chaussures running","href":"/chaussures-running/modeles/toutes/"}],"Type":1}
      },
      {
        path:'/commentaires/chaussures-running/*.html',
        component:TalkPage,
        props:{"breadcrumb":[{"text":"Comentarios","href":"/commentaires/chaussures-running/"}]}
      },
      {
        path:'/chaussures-running/tests/testeurs/:pag(\\d+)?',
        component:AuthorList,
        props:{"filter":"reviews","prodTypeId":"1"}
      },
      {
        path:'/chaussures-running/tests/testeurs/:autor/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"1","breadcrumb":[{"text":"Reviews","href":"/tests/"}]}
      },
      {
        path:'/chaussures-running/tests/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"1","breadcrumb":[{"text":"Reviews","href":"/tests/"}]}
      },
      {
        path:'/chaussures-running/avis/toutes/:pag(\\d+)?',
        component:OpinionList,
        props:{"prodTypeId":"1","breadcrumb":[{"text":"Opiniones","href":"/avis/toutes/"}]}
      },
      {
        path:'/chaussures-running/modeles/toutes/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"1","breadcrumb":[{"text":"Chaussures running","href":"/chaussures-running/modeles/toutes/"}]}
      },
      {
        path:'/chaussures-running/modeles/:marca/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"1","breadcrumb":[{"text":"Chaussures running","href":"/chaussures-running/modeles/toutes/"}]}
      },
      {
        path:'/chaussures-running/outlet/toutes/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"1","breadcrumb":[{"text":"Chaussures running","href":"/chaussures-running/modeles/toutes/"}]}
      },
      {
        path:'/chaussures-running/outlet/:tienda/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"1","breadcrumb":[{"text":"Chaussures running","href":"/chaussures-running/modeles/toutes/"}]}
      },
      {
        path:'/chaussures-running/*/modele/:variant/*',
        component:ProductVariant,
        props:{"name":"ProductPrices","prodTypeId":"1"}
      },
      {
        path:'/chaussures-running/*/amp/prix/toutes/*',
        component:ProductPrices,
        props:{"prodTypeId":"1","breadcrumb":[{"text":"Chaussures running","href":"/chaussures-running/modeles/toutes/"}]},
        meta:{"amp":true}
      },
      {
        path:'/chaussures-running/*/prix/toutes/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"1","breadcrumb":[{"text":"Chaussures running","href":"/chaussures-running/modeles/toutes/"}]}
      },
      {
        path:'/chaussures-running/*/amp/prix/:tienda/*',
        component:ProductPrices,
        props:{"prodTypeId":"1","breadcrumb":[{"text":"Chaussures running","href":"/chaussures-running/modeles/toutes/"}]},
        meta:{"amp":true}
      },
      {
        path:'/chaussures-running/*/prix/:tienda/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"1","breadcrumb":[{"text":"Chaussures running","href":"/chaussures-running/modeles/toutes/"}]}
      },
      {
        path:'/chaussures-running/*index.amp.html',
        component:ProductPag,
        props:{"prodTypeId":"1"},
        meta:{"amp":true}
      },
      {
        path:'/chaussures-running/*',
        component:ProductPag,
        props:{"prodTypeId":"1"}
      },
      {
        path:'/montres-gps-et-cardio/*/tag/:pag(\\d+)?',
        component:TagPage,
        props:{"typeId":"45"}
      },
      {
        path:'/montres-gps-et-cardio/comparateur/',
        component:ComparatorPag,
        props:{"prodTypeId":"45"}
      },
      {
        path:'/montres-gps-et-cardio/comparaison/:prd1/:prd2/*.html',
        component:ComparisonPag,
        props:{"prodTypeId":"45"}
      },
      {
        path:'/montres-gps-et-cardio/questions/recommandations/*',
        component:InquiryList,
        props:{"prodTypeId":"45","breadcrumb":[{"text":"Montre gps et cardio","href":"/montres-gps-et-cardio/modeles/toutes/"}],"Type":1}
      },
      {
        path:'/commentaires/montres-gps-et-cardio/*.html',
        component:TalkPage,
        props:{"breadcrumb":[{"text":"Comentarios","href":"/commentaires/montres-gps-et-cardio/"}]}
      },
      {
        path:'/montres-gps-et-cardio/tests/testeurs/:pag(\\d+)?',
        component:AuthorList,
        props:{"filter":"reviews","prodTypeId":"45"}
      },
      {
        path:'/montres-gps-et-cardio/tests/testeurs/:autor/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"45","breadcrumb":[{"text":"Reviews","href":"/tests/"}]}
      },
      {
        path:'/montres-gps-et-cardio/tests/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"45","breadcrumb":[{"text":"Reviews","href":"/tests/"}]}
      },
      {
        path:'/montres-gps-et-cardio/avis/toutes/:pag(\\d+)?',
        component:OpinionList,
        props:{"prodTypeId":"45","breadcrumb":[{"text":"Opiniones","href":"/avis/toutes/"}]}
      },
      {
        path:'/montres-gps-et-cardio/modeles/toutes/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"45","breadcrumb":[{"text":"Montre gps et cardio","href":"/montres-gps-et-cardio/modeles/toutes/"}]}
      },
      {
        path:'/montres-gps-et-cardio/modeles/:marca/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"45","breadcrumb":[{"text":"Montre gps et cardio","href":"/montres-gps-et-cardio/modeles/toutes/"}]}
      },
      {
        path:'/montres-gps-et-cardio/outlet/toutes/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"45","breadcrumb":[{"text":"Montre gps et cardio","href":"/montres-gps-et-cardio/modeles/toutes/"}]}
      },
      {
        path:'/montres-gps-et-cardio/outlet/:tienda/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"45","breadcrumb":[{"text":"Montre gps et cardio","href":"/montres-gps-et-cardio/modeles/toutes/"}]}
      },
      {
        path:'/montres-gps-et-cardio/*/modele/:variant/*',
        component:ProductVariant,
        props:{"name":"ProductPrices","prodTypeId":"45"}
      },
      {
        path:'/montres-gps-et-cardio/*/amp/prix/toutes/*',
        component:ProductPrices,
        props:{"prodTypeId":"45","breadcrumb":[{"text":"Montre gps et cardio","href":"/montres-gps-et-cardio/modeles/toutes/"}]},
        meta:{"amp":true}
      },
      {
        path:'/montres-gps-et-cardio/*/prix/toutes/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"45","breadcrumb":[{"text":"Montre gps et cardio","href":"/montres-gps-et-cardio/modeles/toutes/"}]}
      },
      {
        path:'/montres-gps-et-cardio/*/amp/prix/:tienda/*',
        component:ProductPrices,
        props:{"prodTypeId":"45","breadcrumb":[{"text":"Montre gps et cardio","href":"/montres-gps-et-cardio/modeles/toutes/"}]},
        meta:{"amp":true}
      },
      {
        path:'/montres-gps-et-cardio/*/prix/:tienda/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"45","breadcrumb":[{"text":"Montre gps et cardio","href":"/montres-gps-et-cardio/modeles/toutes/"}]}
      },
      {
        path:'/montres-gps-et-cardio/*index.amp.html',
        component:ProductPag,
        props:{"prodTypeId":"45"},
        meta:{"amp":true}
      },
      {
        path:'/montres-gps-et-cardio/*',
        component:ProductPag,
        props:{"prodTypeId":"45"}
      },
      {
        path:'/sneakers/*/tag/:pag(\\d+)?',
        component:TagPage,
        props:{"typeId":"50"}
      },
      {
        path:'/sneakers/comparateur/',
        component:ComparatorPag,
        props:{"prodTypeId":"50"}
      },
      {
        path:'/sneakers/comparaison/:prd1/:prd2/*.html',
        component:ComparisonPag,
        props:{"prodTypeId":"50"}
      },
      {
        path:'/sneakers/questions/recommandations/*',
        component:InquiryList,
        props:{"prodTypeId":"50","breadcrumb":[{"text":"Sneakers","href":"/sneakers/modeles/toutes/"}],"Type":1}
      },
      {
        path:'/commentaires/sneakers/*.html',
        component:TalkPage,
        props:{"breadcrumb":[{"text":"Comentarios","href":"/commentaires/sneakers/"}]}
      },
      {
        path:'/sneakers/tests/testeurs/:pag(\\d+)?',
        component:AuthorList,
        props:{"filter":"reviews","prodTypeId":"50"}
      },
      {
        path:'/sneakers/tests/testeurs/:autor/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"50","breadcrumb":[{"text":"Reviews","href":"/tests/"}]}
      },
      {
        path:'/sneakers/tests/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"50","breadcrumb":[{"text":"Reviews","href":"/tests/"}]}
      },
      {
        path:'/sneakers/avis/toutes/:pag(\\d+)?',
        component:OpinionList,
        props:{"prodTypeId":"50","breadcrumb":[{"text":"Opiniones","href":"/avis/toutes/"}]}
      },
      {
        path:'/sneakers/modeles/toutes/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"50","breadcrumb":[{"text":"Sneakers","href":"/sneakers/modeles/toutes/"}]}
      },
      {
        path:'/sneakers/modeles/:marca/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"50","breadcrumb":[{"text":"Sneakers","href":"/sneakers/modeles/toutes/"}]}
      },
      {
        path:'/sneakers/outlet/toutes/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"50","breadcrumb":[{"text":"Sneakers","href":"/sneakers/modeles/toutes/"}]}
      },
      {
        path:'/sneakers/outlet/:tienda/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"50","breadcrumb":[{"text":"Sneakers","href":"/sneakers/modeles/toutes/"}]}
      },
      {
        path:'/sneakers/*/modele/:variant/*',
        component:ProductVariant,
        props:{"name":"ProductPrices","prodTypeId":"50"}
      },
      {
        path:'/sneakers/*/amp/prix/toutes/*',
        component:ProductPrices,
        props:{"prodTypeId":"50","breadcrumb":[{"text":"Sneakers","href":"/sneakers/modeles/toutes/"}]},
        meta:{"amp":true}
      },
      {
        path:'/sneakers/*/prix/toutes/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"50","breadcrumb":[{"text":"Sneakers","href":"/sneakers/modeles/toutes/"}]}
      },
      {
        path:'/sneakers/*/amp/prix/:tienda/*',
        component:ProductPrices,
        props:{"prodTypeId":"50","breadcrumb":[{"text":"Sneakers","href":"/sneakers/modeles/toutes/"}]},
        meta:{"amp":true}
      },
      {
        path:'/sneakers/*/prix/:tienda/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"50","breadcrumb":[{"text":"Sneakers","href":"/sneakers/modeles/toutes/"}]}
      },
      {
        path:'/sneakers/*index.amp.html',
        component:ProductPag,
        props:{"prodTypeId":"50"},
        meta:{"amp":true}
      },
      {
        path:'/sneakers/*',
        component:ProductPag,
        props:{"prodTypeId":"50"}
      },
      {
        path:'/chaussures-fitness/*/tag/:pag(\\d+)?',
        component:TagPage,
        props:{"typeId":"62"}
      },
      {
        path:'/chaussures-fitness/comparateur/',
        component:ComparatorPag,
        props:{"prodTypeId":"62"}
      },
      {
        path:'/chaussures-fitness/comparaison/:prd1/:prd2/*.html',
        component:ComparisonPag,
        props:{"prodTypeId":"62"}
      },
      {
        path:'/chaussures-fitness/questions/recommandations/*',
        component:InquiryList,
        props:{"prodTypeId":"62","breadcrumb":[{"text":"Chaussures fitness","href":"/chaussures-fitness/modeles/toutes/"}],"Type":1}
      },
      {
        path:'/commentaires/chaussures-fitness/*.html',
        component:TalkPage,
        props:{"breadcrumb":[{"text":"Comentarios","href":"/commentaires/chaussures-fitness/"}]}
      },
      {
        path:'/chaussures-fitness/tests/testeurs/:pag(\\d+)?',
        component:AuthorList,
        props:{"filter":"reviews","prodTypeId":"62"}
      },
      {
        path:'/chaussures-fitness/tests/testeurs/:autor/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"62","breadcrumb":[{"text":"Reviews","href":"/tests/"}]}
      },
      {
        path:'/chaussures-fitness/tests/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"62","breadcrumb":[{"text":"Reviews","href":"/tests/"}]}
      },
      {
        path:'/chaussures-fitness/avis/toutes/:pag(\\d+)?',
        component:OpinionList,
        props:{"prodTypeId":"62","breadcrumb":[{"text":"Opiniones","href":"/avis/toutes/"}]}
      },
      {
        path:'/chaussures-fitness/modeles/toutes/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"62","breadcrumb":[{"text":"Chaussures fitness","href":"/chaussures-fitness/modeles/toutes/"}]}
      },
      {
        path:'/chaussures-fitness/modeles/:marca/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"62","breadcrumb":[{"text":"Chaussures fitness","href":"/chaussures-fitness/modeles/toutes/"}]}
      },
      {
        path:'/chaussures-fitness/outlet/toutes/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"62","breadcrumb":[{"text":"Chaussures fitness","href":"/chaussures-fitness/modeles/toutes/"}]}
      },
      {
        path:'/chaussures-fitness/outlet/:tienda/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"62","breadcrumb":[{"text":"Chaussures fitness","href":"/chaussures-fitness/modeles/toutes/"}]}
      },
      {
        path:'/chaussures-fitness/*/modele/:variant/*',
        component:ProductVariant,
        props:{"name":"ProductPrices","prodTypeId":"62"}
      },
      {
        path:'/chaussures-fitness/*/amp/prix/toutes/*',
        component:ProductPrices,
        props:{"prodTypeId":"62","breadcrumb":[{"text":"Chaussures fitness","href":"/chaussures-fitness/modeles/toutes/"}]},
        meta:{"amp":true}
      },
      {
        path:'/chaussures-fitness/*/prix/toutes/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"62","breadcrumb":[{"text":"Chaussures fitness","href":"/chaussures-fitness/modeles/toutes/"}]}
      },
      {
        path:'/chaussures-fitness/*/amp/prix/:tienda/*',
        component:ProductPrices,
        props:{"prodTypeId":"62","breadcrumb":[{"text":"Chaussures fitness","href":"/chaussures-fitness/modeles/toutes/"}]},
        meta:{"amp":true}
      },
      {
        path:'/chaussures-fitness/*/prix/:tienda/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"62","breadcrumb":[{"text":"Chaussures fitness","href":"/chaussures-fitness/modeles/toutes/"}]}
      },
      {
        path:'/chaussures-fitness/*index.amp.html',
        component:ProductPag,
        props:{"prodTypeId":"62"},
        meta:{"amp":true}
      },
      {
        path:'/chaussures-fitness/*',
        component:ProductPag,
        props:{"prodTypeId":"62"}
      },
      {
        path:'/chaussures-crossfit/*/tag/:pag(\\d+)?',
        component:TagPage,
        props:{"typeId":"64"}
      },
      {
        path:'/chaussures-crossfit/comparateur/',
        component:ComparatorPag,
        props:{"prodTypeId":"64"}
      },
      {
        path:'/chaussures-crossfit/comparaison/:prd1/:prd2/*.html',
        component:ComparisonPag,
        props:{"prodTypeId":"64"}
      },
      {
        path:'/chaussures-crossfit/questions/recommandations/*',
        component:InquiryList,
        props:{"prodTypeId":"64","breadcrumb":[{"text":"Chaussures crossfit","href":"/chaussures-crossfit/modeles/toutes/"}],"Type":1}
      },
      {
        path:'/commentaires/chaussures-crossfit/*.html',
        component:TalkPage,
        props:{"breadcrumb":[{"text":"Comentarios","href":"/commentaires/chaussures-crossfit/"}]}
      },
      {
        path:'/chaussures-crossfit/tests/testeurs/:pag(\\d+)?',
        component:AuthorList,
        props:{"filter":"reviews","prodTypeId":"64"}
      },
      {
        path:'/chaussures-crossfit/tests/testeurs/:autor/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"64","breadcrumb":[{"text":"Reviews","href":"/tests/"}]}
      },
      {
        path:'/chaussures-crossfit/tests/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"64","breadcrumb":[{"text":"Reviews","href":"/tests/"}]}
      },
      {
        path:'/chaussures-crossfit/avis/toutes/:pag(\\d+)?',
        component:OpinionList,
        props:{"prodTypeId":"64","breadcrumb":[{"text":"Opiniones","href":"/avis/toutes/"}]}
      },
      {
        path:'/chaussures-crossfit/modeles/toutes/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"64","breadcrumb":[{"text":"Chaussures crossfit","href":"/chaussures-crossfit/modeles/toutes/"}]}
      },
      {
        path:'/chaussures-crossfit/modeles/:marca/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"64","breadcrumb":[{"text":"Chaussures crossfit","href":"/chaussures-crossfit/modeles/toutes/"}]}
      },
      {
        path:'/chaussures-crossfit/outlet/toutes/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"64","breadcrumb":[{"text":"Chaussures crossfit","href":"/chaussures-crossfit/modeles/toutes/"}]}
      },
      {
        path:'/chaussures-crossfit/outlet/:tienda/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"64","breadcrumb":[{"text":"Chaussures crossfit","href":"/chaussures-crossfit/modeles/toutes/"}]}
      },
      {
        path:'/chaussures-crossfit/*/modele/:variant/*',
        component:ProductVariant,
        props:{"name":"ProductPrices","prodTypeId":"64"}
      },
      {
        path:'/chaussures-crossfit/*/amp/prix/toutes/*',
        component:ProductPrices,
        props:{"prodTypeId":"64","breadcrumb":[{"text":"Chaussures crossfit","href":"/chaussures-crossfit/modeles/toutes/"}]},
        meta:{"amp":true}
      },
      {
        path:'/chaussures-crossfit/*/prix/toutes/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"64","breadcrumb":[{"text":"Chaussures crossfit","href":"/chaussures-crossfit/modeles/toutes/"}]}
      },
      {
        path:'/chaussures-crossfit/*/amp/prix/:tienda/*',
        component:ProductPrices,
        props:{"prodTypeId":"64","breadcrumb":[{"text":"Chaussures crossfit","href":"/chaussures-crossfit/modeles/toutes/"}]},
        meta:{"amp":true}
      },
      {
        path:'/chaussures-crossfit/*/prix/:tienda/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"64","breadcrumb":[{"text":"Chaussures crossfit","href":"/chaussures-crossfit/modeles/toutes/"}]}
      },
      {
        path:'/chaussures-crossfit/*index.amp.html',
        component:ProductPag,
        props:{"prodTypeId":"64"},
        meta:{"amp":true}
      },
      {
        path:'/chaussures-crossfit/*',
        component:ProductPag,
        props:{"prodTypeId":"64"}
      },
      {
        path:'/chaussures-spinning/*/tag/:pag(\\d+)?',
        component:TagPage,
        props:{"typeId":"66"}
      },
      {
        path:'/chaussures-spinning/comparateur/',
        component:ComparatorPag,
        props:{"prodTypeId":"66"}
      },
      {
        path:'/chaussures-spinning/comparaison/:prd1/:prd2/*.html',
        component:ComparisonPag,
        props:{"prodTypeId":"66"}
      },
      {
        path:'/chaussures-spinning/questions/recommandations/*',
        component:InquiryList,
        props:{"prodTypeId":"66","breadcrumb":[{"text":"Chaussures spinning","href":"/chaussures-spinning/modeles/toutes/"}],"Type":1}
      },
      {
        path:'/commentaires/chaussures-spinning/*.html',
        component:TalkPage,
        props:{"breadcrumb":[{"text":"Comentarios","href":"/commentaires/chaussures-spinning/"}]}
      },
      {
        path:'/chaussures-spinning/tests/testeurs/:pag(\\d+)?',
        component:AuthorList,
        props:{"filter":"reviews","prodTypeId":"66"}
      },
      {
        path:'/chaussures-spinning/tests/testeurs/:autor/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"66","breadcrumb":[{"text":"Reviews","href":"/tests/"}]}
      },
      {
        path:'/chaussures-spinning/tests/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"66","breadcrumb":[{"text":"Reviews","href":"/tests/"}]}
      },
      {
        path:'/chaussures-spinning/avis/toutes/:pag(\\d+)?',
        component:OpinionList,
        props:{"prodTypeId":"66","breadcrumb":[{"text":"Opiniones","href":"/avis/toutes/"}]}
      },
      {
        path:'/chaussures-spinning/modeles/toutes/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"66","breadcrumb":[{"text":"Chaussures spinning","href":"/chaussures-spinning/modeles/toutes/"}]}
      },
      {
        path:'/chaussures-spinning/modeles/:marca/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"66","breadcrumb":[{"text":"Chaussures spinning","href":"/chaussures-spinning/modeles/toutes/"}]}
      },
      {
        path:'/chaussures-spinning/outlet/toutes/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"66","breadcrumb":[{"text":"Chaussures spinning","href":"/chaussures-spinning/modeles/toutes/"}]}
      },
      {
        path:'/chaussures-spinning/outlet/:tienda/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"66","breadcrumb":[{"text":"Chaussures spinning","href":"/chaussures-spinning/modeles/toutes/"}]}
      },
      {
        path:'/chaussures-spinning/*/modele/:variant/*',
        component:ProductVariant,
        props:{"name":"ProductPrices","prodTypeId":"66"}
      },
      {
        path:'/chaussures-spinning/*/amp/prix/toutes/*',
        component:ProductPrices,
        props:{"prodTypeId":"66","breadcrumb":[{"text":"Chaussures spinning","href":"/chaussures-spinning/modeles/toutes/"}]},
        meta:{"amp":true}
      },
      {
        path:'/chaussures-spinning/*/prix/toutes/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"66","breadcrumb":[{"text":"Chaussures spinning","href":"/chaussures-spinning/modeles/toutes/"}]}
      },
      {
        path:'/chaussures-spinning/*/amp/prix/:tienda/*',
        component:ProductPrices,
        props:{"prodTypeId":"66","breadcrumb":[{"text":"Chaussures spinning","href":"/chaussures-spinning/modeles/toutes/"}]},
        meta:{"amp":true}
      },
      {
        path:'/chaussures-spinning/*/prix/:tienda/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"66","breadcrumb":[{"text":"Chaussures spinning","href":"/chaussures-spinning/modeles/toutes/"}]}
      },
      {
        path:'/chaussures-spinning/*index.amp.html',
        component:ProductPag,
        props:{"prodTypeId":"66"},
        meta:{"amp":true}
      },
      {
        path:'/chaussures-spinning/*',
        component:ProductPag,
        props:{"prodTypeId":"66"}
      },
      {
        path:'/bracelet-connecte/*/tag/:pag(\\d+)?',
        component:TagPage,
        props:{"typeId":"73"}
      },
      {
        path:'/bracelet-connecte/comparateur/',
        component:ComparatorPag,
        props:{"prodTypeId":"73"}
      },
      {
        path:'/bracelet-connecte/comparaison/:prd1/:prd2/*.html',
        component:ComparisonPag,
        props:{"prodTypeId":"73"}
      },
      {
        path:'/bracelet-connecte/questions/recommandations/*',
        component:InquiryList,
        props:{"prodTypeId":"73","breadcrumb":[{"text":"Bracelet connecté","href":"/bracelet-connecte/modeles/toutes/"}],"Type":1}
      },
      {
        path:'/commentaires/bracelet-connecte/*.html',
        component:TalkPage,
        props:{"breadcrumb":[{"text":"Comentarios","href":"/commentaires/bracelet-connecte/"}]}
      },
      {
        path:'/bracelet-connecte/tests/testeurs/:pag(\\d+)?',
        component:AuthorList,
        props:{"filter":"reviews","prodTypeId":"73"}
      },
      {
        path:'/bracelet-connecte/tests/testeurs/:autor/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"73","breadcrumb":[{"text":"Reviews","href":"/tests/"}]}
      },
      {
        path:'/bracelet-connecte/tests/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"73","breadcrumb":[{"text":"Reviews","href":"/tests/"}]}
      },
      {
        path:'/bracelet-connecte/avis/toutes/:pag(\\d+)?',
        component:OpinionList,
        props:{"prodTypeId":"73","breadcrumb":[{"text":"Opiniones","href":"/avis/toutes/"}]}
      },
      {
        path:'/bracelet-connecte/modeles/toutes/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"73","breadcrumb":[{"text":"Bracelet connecté","href":"/bracelet-connecte/modeles/toutes/"}]}
      },
      {
        path:'/bracelet-connecte/modeles/:marca/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"73","breadcrumb":[{"text":"Bracelet connecté","href":"/bracelet-connecte/modeles/toutes/"}]}
      },
      {
        path:'/bracelet-connecte/outlet/toutes/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"73","breadcrumb":[{"text":"Bracelet connecté","href":"/bracelet-connecte/modeles/toutes/"}]}
      },
      {
        path:'/bracelet-connecte/outlet/:tienda/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"73","breadcrumb":[{"text":"Bracelet connecté","href":"/bracelet-connecte/modeles/toutes/"}]}
      },
      {
        path:'/bracelet-connecte/*/modele/:variant/*',
        component:ProductVariant,
        props:{"name":"ProductPrices","prodTypeId":"73"}
      },
      {
        path:'/bracelet-connecte/*/amp/prix/toutes/*',
        component:ProductPrices,
        props:{"prodTypeId":"73","breadcrumb":[{"text":"Bracelet connecté","href":"/bracelet-connecte/modeles/toutes/"}]},
        meta:{"amp":true}
      },
      {
        path:'/bracelet-connecte/*/prix/toutes/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"73","breadcrumb":[{"text":"Bracelet connecté","href":"/bracelet-connecte/modeles/toutes/"}]}
      },
      {
        path:'/bracelet-connecte/*/amp/prix/:tienda/*',
        component:ProductPrices,
        props:{"prodTypeId":"73","breadcrumb":[{"text":"Bracelet connecté","href":"/bracelet-connecte/modeles/toutes/"}]},
        meta:{"amp":true}
      },
      {
        path:'/bracelet-connecte/*/prix/:tienda/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"73","breadcrumb":[{"text":"Bracelet connecté","href":"/bracelet-connecte/modeles/toutes/"}]}
      },
      {
        path:'/bracelet-connecte/*index.amp.html',
        component:ProductPag,
        props:{"prodTypeId":"73"},
        meta:{"amp":true}
      },
      {
        path:'/bracelet-connecte/*',
        component:ProductPag,
        props:{"prodTypeId":"73"}
      },
      {
        path:'/montres-connectees/*/tag/:pag(\\d+)?',
        component:TagPage,
        props:{"typeId":"74"}
      },
      {
        path:'/montres-connectees/comparateur/',
        component:ComparatorPag,
        props:{"prodTypeId":"74"}
      },
      {
        path:'/montres-connectees/comparaison/:prd1/:prd2/*.html',
        component:ComparisonPag,
        props:{"prodTypeId":"74"}
      },
      {
        path:'/montres-connectees/questions/recommandations/*',
        component:InquiryList,
        props:{"prodTypeId":"74","breadcrumb":[{"text":"Montres connectées","href":"/montres-connectees/modeles/toutes/"}],"Type":1}
      },
      {
        path:'/commentaires/montres-connectees/*.html',
        component:TalkPage,
        props:{"breadcrumb":[{"text":"Comentarios","href":"/commentaires/montres-connectees/"}]}
      },
      {
        path:'/montres-connectees/tests/testeurs/:pag(\\d+)?',
        component:AuthorList,
        props:{"filter":"reviews","prodTypeId":"74"}
      },
      {
        path:'/montres-connectees/tests/testeurs/:autor/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"74","breadcrumb":[{"text":"Reviews","href":"/tests/"}]}
      },
      {
        path:'/montres-connectees/tests/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"74","breadcrumb":[{"text":"Reviews","href":"/tests/"}]}
      },
      {
        path:'/montres-connectees/avis/toutes/:pag(\\d+)?',
        component:OpinionList,
        props:{"prodTypeId":"74","breadcrumb":[{"text":"Opiniones","href":"/avis/toutes/"}]}
      },
      {
        path:'/montres-connectees/modeles/toutes/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"74","breadcrumb":[{"text":"Montres connectées","href":"/montres-connectees/modeles/toutes/"}]}
      },
      {
        path:'/montres-connectees/modeles/:marca/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"74","breadcrumb":[{"text":"Montres connectées","href":"/montres-connectees/modeles/toutes/"}]}
      },
      {
        path:'/montres-connectees/outlet/toutes/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"74","breadcrumb":[{"text":"Montres connectées","href":"/montres-connectees/modeles/toutes/"}]}
      },
      {
        path:'/montres-connectees/outlet/:tienda/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"74","breadcrumb":[{"text":"Montres connectées","href":"/montres-connectees/modeles/toutes/"}]}
      },
      {
        path:'/montres-connectees/*/modele/:variant/*',
        component:ProductVariant,
        props:{"name":"ProductPrices","prodTypeId":"74"}
      },
      {
        path:'/montres-connectees/*/amp/prix/toutes/*',
        component:ProductPrices,
        props:{"prodTypeId":"74","breadcrumb":[{"text":"Montres connectées","href":"/montres-connectees/modeles/toutes/"}]},
        meta:{"amp":true}
      },
      {
        path:'/montres-connectees/*/prix/toutes/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"74","breadcrumb":[{"text":"Montres connectées","href":"/montres-connectees/modeles/toutes/"}]}
      },
      {
        path:'/montres-connectees/*/amp/prix/:tienda/*',
        component:ProductPrices,
        props:{"prodTypeId":"74","breadcrumb":[{"text":"Montres connectées","href":"/montres-connectees/modeles/toutes/"}]},
        meta:{"amp":true}
      },
      {
        path:'/montres-connectees/*/prix/:tienda/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"74","breadcrumb":[{"text":"Montres connectées","href":"/montres-connectees/modeles/toutes/"}]}
      },
      {
        path:'/montres-connectees/*index.amp.html',
        component:ProductPag,
        props:{"prodTypeId":"74"},
        meta:{"amp":true}
      },
      {
        path:'/montres-connectees/*',
        component:ProductPag,
        props:{"prodTypeId":"74"}
      },
      {
        path:'/compteurs-gps/*/tag/:pag(\\d+)?',
        component:TagPage,
        props:{"typeId":"75"}
      },
      {
        path:'/compteurs-gps/comparateur/',
        component:ComparatorPag,
        props:{"prodTypeId":"75"}
      },
      {
        path:'/compteurs-gps/comparaison/:prd1/:prd2/*.html',
        component:ComparisonPag,
        props:{"prodTypeId":"75"}
      },
      {
        path:'/compteurs-gps/questions/recommandations/*',
        component:InquiryList,
        props:{"prodTypeId":"75","breadcrumb":[{"text":"Compteurs GPS vélo","href":"/compteurs-gps/modeles/toutes/"}],"Type":1}
      },
      {
        path:'/commentaires/compteurs-gps/*.html',
        component:TalkPage,
        props:{"breadcrumb":[{"text":"Comentarios","href":"/commentaires/compteurs-gps/"}]}
      },
      {
        path:'/compteurs-gps/tests/testeurs/:pag(\\d+)?',
        component:AuthorList,
        props:{"filter":"reviews","prodTypeId":"75"}
      },
      {
        path:'/compteurs-gps/tests/testeurs/:autor/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"75","breadcrumb":[{"text":"Reviews","href":"/tests/"}]}
      },
      {
        path:'/compteurs-gps/tests/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"75","breadcrumb":[{"text":"Reviews","href":"/tests/"}]}
      },
      {
        path:'/compteurs-gps/avis/toutes/:pag(\\d+)?',
        component:OpinionList,
        props:{"prodTypeId":"75","breadcrumb":[{"text":"Opiniones","href":"/avis/toutes/"}]}
      },
      {
        path:'/compteurs-gps/modeles/toutes/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"75","breadcrumb":[{"text":"Compteurs GPS vélo","href":"/compteurs-gps/modeles/toutes/"}]}
      },
      {
        path:'/compteurs-gps/modeles/:marca/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"75","breadcrumb":[{"text":"Compteurs GPS vélo","href":"/compteurs-gps/modeles/toutes/"}]}
      },
      {
        path:'/compteurs-gps/outlet/toutes/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"75","breadcrumb":[{"text":"Compteurs GPS vélo","href":"/compteurs-gps/modeles/toutes/"}]}
      },
      {
        path:'/compteurs-gps/outlet/:tienda/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"75","breadcrumb":[{"text":"Compteurs GPS vélo","href":"/compteurs-gps/modeles/toutes/"}]}
      },
      {
        path:'/compteurs-gps/*/modele/:variant/*',
        component:ProductVariant,
        props:{"name":"ProductPrices","prodTypeId":"75"}
      },
      {
        path:'/compteurs-gps/*/amp/prix/toutes/*',
        component:ProductPrices,
        props:{"prodTypeId":"75","breadcrumb":[{"text":"Compteurs GPS vélo","href":"/compteurs-gps/modeles/toutes/"}]},
        meta:{"amp":true}
      },
      {
        path:'/compteurs-gps/*/prix/toutes/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"75","breadcrumb":[{"text":"Compteurs GPS vélo","href":"/compteurs-gps/modeles/toutes/"}]}
      },
      {
        path:'/compteurs-gps/*/amp/prix/:tienda/*',
        component:ProductPrices,
        props:{"prodTypeId":"75","breadcrumb":[{"text":"Compteurs GPS vélo","href":"/compteurs-gps/modeles/toutes/"}]},
        meta:{"amp":true}
      },
      {
        path:'/compteurs-gps/*/prix/:tienda/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"75","breadcrumb":[{"text":"Compteurs GPS vélo","href":"/compteurs-gps/modeles/toutes/"}]}
      },
      {
        path:'/compteurs-gps/*index.amp.html',
        component:ProductPag,
        props:{"prodTypeId":"75"},
        meta:{"amp":true}
      },
      {
        path:'/compteurs-gps/*',
        component:ProductPag,
        props:{"prodTypeId":"75"}
      },
      {
        path:'/chaussures-randonnee/*/tag/:pag(\\d+)?',
        component:TagPage,
        props:{"typeId":"84"}
      },
      {
        path:'/chaussures-randonnee/comparateur/',
        component:ComparatorPag,
        props:{"prodTypeId":"84"}
      },
      {
        path:'/chaussures-randonnee/comparaison/:prd1/:prd2/*.html',
        component:ComparisonPag,
        props:{"prodTypeId":"84"}
      },
      {
        path:'/chaussures-randonnee/questions/recommandations/*',
        component:InquiryList,
        props:{"prodTypeId":"84","breadcrumb":[{"text":"Chaussures randonnée","href":"/chaussures-randonnee/modeles/toutes/"}],"Type":1}
      },
      {
        path:'/commentaires/chaussures-randonnee/*.html',
        component:TalkPage,
        props:{"breadcrumb":[{"text":"Comentarios","href":"/commentaires/chaussures-randonnee/"}]}
      },
      {
        path:'/chaussures-randonnee/tests/testeurs/:pag(\\d+)?',
        component:AuthorList,
        props:{"filter":"reviews","prodTypeId":"84"}
      },
      {
        path:'/chaussures-randonnee/tests/testeurs/:autor/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"84","breadcrumb":[{"text":"Reviews","href":"/tests/"}]}
      },
      {
        path:'/chaussures-randonnee/tests/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"84","breadcrumb":[{"text":"Reviews","href":"/tests/"}]}
      },
      {
        path:'/chaussures-randonnee/avis/toutes/:pag(\\d+)?',
        component:OpinionList,
        props:{"prodTypeId":"84","breadcrumb":[{"text":"Opiniones","href":"/avis/toutes/"}]}
      },
      {
        path:'/chaussures-randonnee/modeles/toutes/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"84","breadcrumb":[{"text":"Chaussures randonnée","href":"/chaussures-randonnee/modeles/toutes/"}]}
      },
      {
        path:'/chaussures-randonnee/modeles/:marca/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"84","breadcrumb":[{"text":"Chaussures randonnée","href":"/chaussures-randonnee/modeles/toutes/"}]}
      },
      {
        path:'/chaussures-randonnee/outlet/toutes/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"84","breadcrumb":[{"text":"Chaussures randonnée","href":"/chaussures-randonnee/modeles/toutes/"}]}
      },
      {
        path:'/chaussures-randonnee/outlet/:tienda/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"84","breadcrumb":[{"text":"Chaussures randonnée","href":"/chaussures-randonnee/modeles/toutes/"}]}
      },
      {
        path:'/chaussures-randonnee/*/modele/:variant/*',
        component:ProductVariant,
        props:{"name":"ProductPrices","prodTypeId":"84"}
      },
      {
        path:'/chaussures-randonnee/*/amp/prix/toutes/*',
        component:ProductPrices,
        props:{"prodTypeId":"84","breadcrumb":[{"text":"Chaussures randonnée","href":"/chaussures-randonnee/modeles/toutes/"}]},
        meta:{"amp":true}
      },
      {
        path:'/chaussures-randonnee/*/prix/toutes/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"84","breadcrumb":[{"text":"Chaussures randonnée","href":"/chaussures-randonnee/modeles/toutes/"}]}
      },
      {
        path:'/chaussures-randonnee/*/amp/prix/:tienda/*',
        component:ProductPrices,
        props:{"prodTypeId":"84","breadcrumb":[{"text":"Chaussures randonnée","href":"/chaussures-randonnee/modeles/toutes/"}]},
        meta:{"amp":true}
      },
      {
        path:'/chaussures-randonnee/*/prix/:tienda/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"84","breadcrumb":[{"text":"Chaussures randonnée","href":"/chaussures-randonnee/modeles/toutes/"}]}
      },
      {
        path:'/chaussures-randonnee/*index.amp.html',
        component:ProductPag,
        props:{"prodTypeId":"84"},
        meta:{"amp":true}
      },
      {
        path:'/chaussures-randonnee/*',
        component:ProductPag,
        props:{"prodTypeId":"84"}
      },
      {
        path:'/chaussures-montagne/*/tag/:pag(\\d+)?',
        component:TagPage,
        props:{"typeId":"1041"}
      },
      {
        path:'/chaussures-montagne/comparateur/',
        component:ComparatorPag,
        props:{"prodTypeId":"1041"}
      },
      {
        path:'/chaussures-montagne/comparaison/:prd1/:prd2/*.html',
        component:ComparisonPag,
        props:{"prodTypeId":"1041"}
      },
      {
        path:'/chaussures-montagne/questions/recommandations/*',
        component:InquiryList,
        props:{"prodTypeId":"1041","breadcrumb":[{"text":"Chaussures montagne","href":"/chaussures-montagne/modeles/toutes/"}],"Type":1}
      },
      {
        path:'/commentaires/chaussures-montagne/*.html',
        component:TalkPage,
        props:{"breadcrumb":[{"text":"Comentarios","href":"/commentaires/chaussures-montagne/"}]}
      },
      {
        path:'/chaussures-montagne/tests/testeurs/:pag(\\d+)?',
        component:AuthorList,
        props:{"filter":"reviews","prodTypeId":"1041"}
      },
      {
        path:'/chaussures-montagne/tests/testeurs/:autor/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"1041","breadcrumb":[{"text":"Reviews","href":"/tests/"}]}
      },
      {
        path:'/chaussures-montagne/tests/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"1041","breadcrumb":[{"text":"Reviews","href":"/tests/"}]}
      },
      {
        path:'/chaussures-montagne/avis/toutes/:pag(\\d+)?',
        component:OpinionList,
        props:{"prodTypeId":"1041","breadcrumb":[{"text":"Opiniones","href":"/avis/toutes/"}]}
      },
      {
        path:'/chaussures-montagne/modeles/toutes/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"1041","breadcrumb":[{"text":"Chaussures montagne","href":"/chaussures-montagne/modeles/toutes/"}]}
      },
      {
        path:'/chaussures-montagne/modeles/:marca/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"1041","breadcrumb":[{"text":"Chaussures montagne","href":"/chaussures-montagne/modeles/toutes/"}]}
      },
      {
        path:'/chaussures-montagne/outlet/toutes/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"1041","breadcrumb":[{"text":"Chaussures montagne","href":"/chaussures-montagne/modeles/toutes/"}]}
      },
      {
        path:'/chaussures-montagne/outlet/:tienda/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"1041","breadcrumb":[{"text":"Chaussures montagne","href":"/chaussures-montagne/modeles/toutes/"}]}
      },
      {
        path:'/chaussures-montagne/*/modele/:variant/*',
        component:ProductVariant,
        props:{"name":"ProductPrices","prodTypeId":"1041"}
      },
      {
        path:'/chaussures-montagne/*/amp/prix/toutes/*',
        component:ProductPrices,
        props:{"prodTypeId":"1041","breadcrumb":[{"text":"Chaussures montagne","href":"/chaussures-montagne/modeles/toutes/"}]},
        meta:{"amp":true}
      },
      {
        path:'/chaussures-montagne/*/prix/toutes/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"1041","breadcrumb":[{"text":"Chaussures montagne","href":"/chaussures-montagne/modeles/toutes/"}]}
      },
      {
        path:'/chaussures-montagne/*/amp/prix/:tienda/*',
        component:ProductPrices,
        props:{"prodTypeId":"1041","breadcrumb":[{"text":"Chaussures montagne","href":"/chaussures-montagne/modeles/toutes/"}]},
        meta:{"amp":true}
      },
      {
        path:'/chaussures-montagne/*/prix/:tienda/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"1041","breadcrumb":[{"text":"Chaussures montagne","href":"/chaussures-montagne/modeles/toutes/"}]}
      },
      {
        path:'/chaussures-montagne/*index.amp.html',
        component:ProductPag,
        props:{"prodTypeId":"1041"},
        meta:{"amp":true}
      },
      {
        path:'/chaussures-montagne/*',
        component:ProductPag,
        props:{"prodTypeId":"1041"}
      },
      {
        path:'/brassieres-de-sport/outlet/toutes/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"58","breadcrumb":[{"text":"Brassieres sport","href":"/brassieres-de-sport/outlet/toutes/"}]}
      },
      {
        path:'/brassieres-de-sport/outlet/:tienda/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"58","breadcrumb":[{"text":"Brassieres sport","href":"/brassieres-de-sport/outlet/toutes/"}]}
      },
      {
        path:'/appareils-fitness/toutes/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"105","breadcrumb":[{"text":"Appareils de fitness","href":"/appareils-fitness/toutes/"}]}
      },
      {
        path:'/appareils-fitness/:tienda/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"105","breadcrumb":[{"text":"Appareils de fitness","href":"/appareils-fitness/toutes/"}]}
      },
      {
        path:'/codes-promo-running/',
        component:DiscountCodesList
      },
      {
        path:'/calculateurs-allure/calcul-temps/',
        component:Calculator
      },
      {
        path:'/calculateurs-allure/calcul-temps/marathon/',
        component:CalculatorMarathon
      },
      {
        path:'/calculateurs-allure/calcul-temps/semi-marathon/',
        component:CalculatorHalfMarathon
      },
      {
        path:'/calculateurs-allure/calcul-temps/10-km/',
        component:Calculator10Km
      },
      {
        path:'/page-non-trouvee',
        component:NotFoundPage
      },
      {
        path:'/404.html',
        component:NotFoundPage
      },
      {
        path:'/enlace/',
        component:RedirectingPag
      },
      {
        path:'/*/enlace/',
        component:RedirectingPag
      },
      {
        path:'/running-music/list',
        component:AudiosList
      },
      {
        path:'/running-music/playlist/:id',
        component:AudiosPage
      },
      {
        path:'/auth/login',
        component:AuthLogin
      },
      {
        path:'/auth/redirect',
        component:AuthRedirect
      },
      {
        path:'/auth/callback',
        component:AuthCallback
      },
      {
        path:'/auth/data',
        component:AuthData
      },
      {
        path:'/auth/newsletter',
        component:AuthNewsletter
      },
      {
        path:'/auth/remember',
        component:AuthRemember
      },
      {
        path:'/auth/password/:token',
        component:AuthPassword
      },
      {
        path:'/auth/signin',
        component:AuthSignin
      },
      {
        path:'/countries/:country',
        component:CountriesRedirect
      },
      {
        path:'/*',
        component:LandingPag
      }
    ]
  })
}
